import { type NavbarConfigSectionColumnFragment } from "@gql-schema";
import { MobileMenuItemBlock } from "@ui/components/NavBar/styles/MobileMenuItemBlock";
import { FC, PropsWithChildren } from "react";
import { MobileSubmenuDropDownLevel1Title } from "./MobileSubmenuDropDownLevel1Title";

interface MobileSubmenuDropDownLevel1Props {
    data: NavbarConfigSectionColumnFragment;
}

export const MobileSubmenuDropDownLevel1: FC<PropsWithChildren<MobileSubmenuDropDownLevel1Props>> = (props) => {
    const { data } = props;

    const hasData = data.items.length > 0;

    if (!hasData) {
        return null;
    }

    return (
        <MobileMenuItemBlock $firstOffset={8} $lastOffset={8}>
            <MobileSubmenuDropDownLevel1Title label={data.items[0].label} href={data.items[0].href} />
        </MobileMenuItemBlock>
    );
};
