import { FC, PropsWithChildren } from "react";

type Props = { className?: string };

export const NavBarContainerDesktop: FC<PropsWithChildren<Props>> = (props) => (
    <div
        className={`container mx-auto hidden h-[72px] w-full items-center justify-between md:flex xl:h-[92px] ${
            props.className ?? ""
        }`}
    >
        {props.children}
    </div>
);
