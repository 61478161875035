import { type NavbarConfigSectionFragment } from "@gql-schema";
import { FC, PropsWithChildren } from "react";
import { MobileSubmenuDropDownLevel1 } from "./MobileSubmenuDropDownLevel1";

interface MobileSubmenuDropDownProps {
    data: NavbarConfigSectionFragment;
}

export const MobileSubmenuDropDown: FC<PropsWithChildren<MobileSubmenuDropDownProps>> = ({ data }) => {
    return (
        <ul>
            {data.columns.map((item, index) => (
                <MobileSubmenuDropDownLevel1 data={item} key={index} />
            ))}
        </ul>
    );
};
