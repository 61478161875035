import { Breakpoints, BREAKPOINTS } from "@ui/theme/breakpoints";
import { COLORS } from "@ui/theme/colors";
import { injectCss } from "@uxf/styles/mixins/injectCss";
import { responsiveHidden } from "@uxf/styles/mixins/responsiveHidden";
import { responsiveHideVisually } from "@uxf/styles/mixins/responsiveHideVisually";
import { Margins } from "@uxf/styles/properties/margins";
import { Visibility } from "@uxf/styles/properties/visibility";
import { formatResponsiveValue, formatValue } from "@uxf/styles/utils/formatValue";
import { resolveResponsiveProperties } from "@uxf/styles/utils/responsive";
import { HTMLAttributes } from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

interface DividerProps
    extends HTMLAttributes<HTMLSpanElement>,
        Pick<Margins, "$mb" | "$mt" | "$my">,
        Pick<Visibility<Breakpoints>, "$hidden" | "$hideVisually"> {
    as?: keyof Pick<JSX.IntrinsicElements, "hr" | "span">;
    $css?: FlattenSimpleInterpolation;
    $lineColor?: string;
    $lineSize?: number | string;
}

export const Divider = styled("hr")<DividerProps>(
    ({
        $css,
        $lineColor = COLORS.green.secondary,
        $lineSize = "1px",
        $mb,
        $mt,
        $my = 0,
        $hidden,
        $hideVisually,
    }) => css`
        backface-visibility: hidden;
        border: 0;
        border-bottom: ${formatValue($lineSize)} solid ${$lineColor};
        width: 100%;
        display: block;
        pointer-events: none;

        ${resolveResponsiveProperties(BREAKPOINTS, {
            marginBottom: formatResponsiveValue($mb ?? $my),
            marginTop: formatResponsiveValue($mt ?? $my),
        })};

        ${responsiveHideVisually(BREAKPOINTS, $hideVisually)};
        ${responsiveHidden(BREAKPOINTS, $hidden)};

        ${injectCss($css)};
    `,
);
