import { SiteLogo, SiteLogoProps } from "@ui/components/SiteLogo/SiteLogo";
import { useBooleanWithRouteTransition } from "@ui/hooks/useBooleanWithRouteTransition";
import { useCloseToggleLayerOnEscKey } from "@ui/hooks/useCloseToggleLayerOnEscKey";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { useBodyScrollLock } from "@uxf/core/hooks/useBodyScrollLock";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import { useMinWindowWidth } from "@uxf/core/hooks/useMinWindowWidth";
import { FC, PropsWithChildren, useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { NavBarMenuTrigger, NavBarMenuTriggerProps } from "./nav-bar-menu-trigger";
import { NavBarModal } from "./nav-bar-modal";
import { NavBarContainerMobile } from "./styles/NavBarContainerMobile";

export interface NavBarBurgerMobileMenuProps extends Omit<NavBarMenuTriggerProps, "onClick" | "params" | "route"> {
    logoRoute: SiteLogoProps["route"];
    menuId: string;
}

export const NavBarBurgerMobileMenu: FC<PropsWithChildren<NavBarBurgerMobileMenuProps>> = (props) => {
    const modalRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLAnchorElement>(null);

    const { children, menuId, logoRoute, ...restProps } = props;

    const [isOpen, setIsOpen] = useBooleanWithRouteTransition(false);

    const close = useCallback(() => setIsOpen(false), [setIsOpen]);
    const toggle = useCallback(() => setIsOpen((prev) => !prev), [setIsOpen]);

    const isMounted = useIsMounted();
    const isNotMobile = useMinWindowWidth(BREAKPOINTS.md);

    useBodyScrollLock<HTMLDivElement>(modalRef, isOpen);

    useCloseToggleLayerOnEscKey(isOpen, close, modalRef, triggerRef);

    useEffect(() => {
        const node = modalRef.current;
        if (node) {
            node.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        if (isNotMobile && isOpen) {
            close();
        }
    }, [close, isNotMobile, isOpen]);

    return (
        <>
            <NavBarMenuTrigger
                aria-controls={menuId}
                aria-haspopup
                aria-expanded={isOpen}
                onClick={toggle}
                ref={triggerRef}
                {...restProps}
            />
            {isMounted &&
                isOpen &&
                createPortal(
                    <NavBarModal id={menuId} onBackdropClick={close} ref={modalRef}>
                        <NavBarContainerMobile className="justify-between px-6">
                            <SiteLogo route={logoRoute} />
                            <NavBarMenuTrigger isOpen={isOpen} onClick={close} />
                        </NavBarContainerMobile>
                        {children}
                    </NavBarModal>,
                    document.body,
                )}
        </>
    );
};
