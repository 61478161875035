import { type NavbarConfigFragment } from "@gql-schema";
import { Box } from "@ui/components/Box/Box";
import {
    MobileMenuItemClickable,
    MobileMenuItemClickableProps,
} from "@ui/components/NavBar/styles/MobileMenuItemClickable";
import { COLORS } from "@ui/theme/colors";
import { CLASSES } from "@uxf/core/constants/classes";
import { cx } from "@uxf/core/utils/cx";
import { FC, PropsWithChildren, ReactNode, memo, useMemo } from "react";
import { MobileSubmenuDropDown } from "./MobileSubmenuDropDown";
import { hasSubmenu } from "./hasSubmenu";

interface Props extends MobileMenuItemClickableProps {
    isActive?: boolean;
    data: NavbarConfigFragment;
    index: number;
    startIcon?: ReactNode;
    title: string;
}

const Component: FC<PropsWithChildren<Props>> = (props) => {
    const { className, data, isActive, index, startIcon, title, ...restProps } = props;

    const submenuData = data.sections[index];

    const _hasSubmenu = useMemo(() => hasSubmenu(submenuData), [submenuData]);

    return (
        <li>
            <MobileMenuItemClickable
                className={cx(isActive && CLASSES.IS_ACTIVE, className, "font-semibold")}
                data-menukey={index}
                $color={COLORS.green.default}
                {...restProps}
            >
                {!!startIcon && (
                    <Box $flexShrink={0} $mr={8}>
                        {startIcon}
                    </Box>
                )}
                <div className="mobile-menu-title text-16 leading-tight">{title}</div>
            </MobileMenuItemClickable>
            {_hasSubmenu && <MobileSubmenuDropDown data={submenuData} />}
        </li>
    );
};

export const MainNavBarMobileMenuItem = memo(Component);

MainNavBarMobileMenuItem.displayName = "MainNavBarMobileMenuItem";
