import { ColorProperty } from "@uxf/styles/types";
import { formatValue } from "@uxf/styles/utils/formatValue";
import { transition } from "@uxf/styles/utils/transition";
import { Property } from "csstype";
import { LiHTMLAttributes } from "react";
import styled, { css } from "styled-components";

export interface MobileMenuItemBlockProps extends LiHTMLAttributes<HTMLLIElement> {
    $backgroundColor?: ColorProperty;
    $firstOffset?: Property.Padding<number>;
    $lastOffset?: Property.Padding<number>;
}

export const MobileMenuItemBlock = styled("li")<MobileMenuItemBlockProps>(
    ({ $backgroundColor, $firstOffset, $lastOffset }) => css`
        background-color: ${$backgroundColor};
        transition: ${transition("backgroundColor", 100)};

        &:first-child {
            padding-top: ${formatValue($firstOffset)};
        }

        &:last-child {
            padding-bottom: ${formatValue($lastOffset)};
        }
    `,
);
