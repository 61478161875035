import { type NavbarConfigItemFragment } from "@gql-schema";
import { MobileMenuItemClickable } from "@ui/components/NavBar/styles/MobileMenuItemClickable";
import { COLORS } from "@ui/theme/colors";
import { FC, memo } from "react";

type MobileSubmenuDropDownLevel1TitleProps = NavbarConfigItemFragment;

const MobileSubmenuDropDownLevel1TitleComponent: FC<MobileSubmenuDropDownLevel1TitleProps> = ({ href, label }) => {
    try {
        return (
            <MobileMenuItemClickable
                $color={COLORS.green.default}
                $minHeight={44}
                $pl={48}
                $textTransform="none"
                $isFullWidth
                href={href}
            >
                <div className="mobile-menu-title text-14 font-semibold leading-tight">{label}</div>
            </MobileMenuItemClickable>
        );
    } catch {
        return null;
    }
};

export const MobileSubmenuDropDownLevel1Title = memo(MobileSubmenuDropDownLevel1TitleComponent);

MobileSubmenuDropDownLevel1Title.displayName = "MobileSubmenuDropDownLevel1Title";
