import { CUSTOMER_ZONE_MENU_ITEM, USER_MENU } from "@config/configureMenu";
import { MyProfileFragment, type NavbarConfigFragment } from "@gql-schema";
import { useOnLogin } from "@shared/components/Navigation/elements/useOnLogin";
import { ButtonOpaque } from "@ui/components/Button/ButtonOpaque";
import { NavBarLoginButtonMobile } from "@ui/components/NavBar/nav-bar-login-button-mobile";
import { memo } from "react";
import { LoggedUserMenu } from "./LoggedUserMenu";
import { MainNavBarMobileMenuItem } from "./MainNavBarMobileMenuItem";

const LOGIN_ITEM = USER_MENU.login;

interface Props {
    activeMenuItemIndex?: number;
    className?: string;
    hideLandingPageMenu?: boolean;
    navbarData?: NavbarConfigFragment;
    profile: MyProfileFragment | null;
    showFullCustomerZone?: boolean;
}

export const MobileMenu = memo<Props>((props) => {
    const { activeMenuItemIndex, className, hideLandingPageMenu, navbarData, profile, showFullCustomerZone } = props;

    const onLogin = useOnLogin(profile);

    const title = <span className="block uppercase">{CUSTOMER_ZONE_MENU_ITEM.title}</span>;

    return (
        <div className={className}>
            {!hideLandingPageMenu && (
                <ul>
                    {navbarData?.sections.map((item, index) => (
                        <MainNavBarMobileMenuItem
                            data={navbarData}
                            href={item.href}
                            index={index}
                            isActive={index === activeMenuItemIndex}
                            key={index}
                            title={item.label}
                        />
                    ))}
                </ul>
            )}
            {!profile?.email || !profile.isLogged ? (
                <div className="flex items-center justify-between border-t border-green-secondaryDark p-6">
                    <NavBarLoginButtonMobile
                        analyticsCallback={!profile?.email ? LOGIN_ITEM.analyticsCallback : undefined}
                        menuKey={LOGIN_ITEM.menuKey}
                        onClick={profile?.email ? onLogin : undefined}
                        route={!profile?.email ? LOGIN_ITEM.route : undefined}
                        title={
                            profile?.name ? (
                                <>
                                    {title}
                                    <span className="block text-10 leading-normal opacity-60">
                                        {
                                            !`přihlášen ${
                                                profile.surname ? `${profile.name} ${profile.surname}` : profile.surname
                                            }`
                                        }
                                    </span>
                                </>
                            ) : (
                                title
                            )
                        }
                    />
                    <ButtonOpaque route="watch-wolf-zone/entry-page" $py={8} $px={16} $fontSize={14} $fontWeight={500}>
                        Hlídací vlk
                    </ButtonOpaque>
                </div>
            ) : (
                <LoggedUserMenu
                    className={!hideLandingPageMenu ? "mt-4" : undefined}
                    showFullCustomerZone={showFullCustomerZone}
                    userName={profile.name ?? "Můj Kalkulátor"}
                />
            )}
        </div>
    );
});

MobileMenu.displayName = "MobileMenu";
